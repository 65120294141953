<template>
  <v-card>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <h4 class="text-center py-4">{{ system.systemName }}</h4>

    <v-btn color="cyan lighten-1 mr-2 ml-2" dark @click="changeitemType('all')"
      >All</v-btn
    >
    <v-btn
      color="green lighten-1 mr-2 "
      dark
      @click="changeitemType('finished')"
      >Finished</v-btn
    >
    <v-btn
      color="yellow darken-4 mr-2 "
      dark
      @click="changeitemType('executable')"
      >Executable
    </v-btn>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :search="search"
      :footer-props="{
        'items-per-page-options': itemsPerPage
      }"
      class="elevation-1 text-center"
    >
      <template v-slot:item.is_finish="{ item }">
        <v-tooltip left v-if="item.is_finish">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-check-circle
            </v-icon>
          </template>
          <span>finish</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-4" v-bind="attrs" v-on="on" dark>
              mdi-close-circle
            </v-icon>
          </template>
          <span>executable</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              color="red darken-2"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>delete</span>
        </v-tooltip>
      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template> -->
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete -
          <b>'{{ deletedItemName }}'</b> ?</v-card-title
        >
        <v-card-actions>
          <v-btn color="blue darken-1" dark @click="closeDelete">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red lighten-1" dark @click="deleteItemConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import store from '@/store/index'
import EventService from '@/services/EventService.js'
import { paginationMixin } from '@/mixins/PaginationMixin.js'

export default {
  // props: {
  //   systemName: {
  //     type: String,
  //     required: true
  //   }
  // },
  mixins: [paginationMixin],
  created() {
    this.setBreadcrumbs([
      { text: 'Home', to: { path: '/' } },
      { text: ':systems' },
      { text: ':system' } // placeholder
    ])
    this.replaceBreadcrumb({
      find: ':system',
      replace: {
        text: `${this.system.systemName} events`,
        to: {
          name: 'systemEvents',
          params: { system_id: this.system.systemId }
        },
        disabled: true
      }
    })
    this.replaceBreadcrumb({
      find: ':systems',
      replace: {
        text: this.system.systemTypeName,
        to: {
          name: 'systemsByType',
          params: {
            system_type: this.system.systemType,
            TypeName: this.system.systemTypeName
          }
        }
      }
    })
  },
  data() {
    return {
      headers: [
        {
          text: 'Title',
          align: 'center',
          filterable: false,
          value: 'title'
        },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Start Time', value: 'start_time' },
        { text: 'Finish Time', value: 'finish_time' },
        { text: 'Time Delta', value: 'time_delta' },
        { text: 'Finish', value: 'is_finish' },
        { text: 'Drop', value: 'action', sortable: false }
      ]
    }
  },
  methods: {
    changeitemType(type) {
      this.$router
        .push({
          name: 'systemEvents',
          params: { systemId: this.systemId },
          query: { type: type }
        })
        .catch(() => {})
    },

    getDataFromAPI(type, offset) {
      // console.log(this.options)
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = []
        type = type !== undefined ? type : this.itemType
        const query = this.$route.query
        let total = 0
        let sort_by = sortBy.length == 1 ? sortBy[0] : 'start_time'
        let sort = sortDesc.length && sortDesc[0] == 1 ? -1 : 1
        offset = offset !== 0 ? itemsPerPage * (page - 1) : 0
        EventService.getEvents(
          this.$route.params.system_id,
          itemsPerPage,
          offset || 0,
          sort_by,
          sort,
          type,
          this.search
        )
          .then(response => {
            // console.log(response.data.results)
            items = response.data.results
            total = response.data.count
            resolve({ items, total })
          })
          .catch(error => {
            console.log(error)
            store.dispatch('setLoader', false)
          })
      })
    },
    deleteItemConfirm() {
      const index = this.items.indexOf(this.DeleteItem)
      EventService.deleteEvent(this.DeleteItem._id).then(() => {
        this.items.splice(index, 1)
        this.closeDelete()
        const notification = {
          type: 'success',
          message: `${this.DeleteItem.title} deleted.`
        }
        this.$store.dispatch('notification/add', notification, { root: true })
      })
    }
  }
}
</script>

<style scoped></style>
